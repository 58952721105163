import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCheckbox from '@/components/controls/CustomFormCheckbox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type BillingAddressFormProps = {
  form: UseFormReturn<any>;
  isFormShown: boolean;
};

const BillingAddressForm: FC<BillingAddressFormProps> = ({
  form,
  isFormShown,
}) => {
  return (
    <div className="mb-5">
      <CustomFormCheckbox
        form={form}
        name="differentBillingAddress"
        labelText="Da li se adresa za dostavu računa razlikuje?"
        formItemStyle="flex flex-col gap-2 mt-5 px-2"
        checkboxStyle="w-5 h-5 data-[state=checked]:bg-[#007bff] data-[state=checked]:text-neutral-50"
      />
      {isFormShown && (
        <>
          <CustomFormInput
            customForm={form}
            name="billingAddress"
            labelText="Adresa dostave"
            formItemStyle="flex flex-col w-full pb-5 mt-5 px-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="billingPlace"
            labelText="Mesto dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="billingCountry"
            labelText="Drzava dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
        </>
      )}
    </div>
  );
};

export default BillingAddressForm;
