import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddUserForDistributorForm from '@/components/forms/AddUserForDistributorForm.tsx';
import UsersForDistributorSection from '@/components/layout/distributors/UsersForDistributorSection.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getUsersForDropdown } from '@/redux/users/users.actions.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

const EditDistributorsUserSection: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetUserDropdownResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsersForDropdown = async () => {
      // @ts-ignore
      const response = await dispatch(getUsersForDropdown()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersForDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <Card x-chunk="card-distributor-users">
        <CardHeader>
          <CardTitle>Dodaj novog korisnika</CardTitle>
        </CardHeader>
        <CardContent>
          <AddUserForDistributorForm />
        </CardContent>
      </Card>

      <Card x-chunk="card-distributor-users">
        <CardContent>
          <UsersForDistributorSection />
        </CardContent>
      </Card>
    </div>
  );
};

export default EditDistributorsUserSection;
