import { MoreHorizontal } from 'lucide-react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { Distributor } from '@/redux/distributors/distributors.types.ts';
import { formatCreationDate } from '@/utils/dateUtil.ts';

type DistributorTableRowProps = {
  distributor: Distributor;
  onDeleteDistributor: (distributorId: string) => void;
};

const DistributorTableRow: FC<DistributorTableRowProps> = ({
  distributor,
  onDeleteDistributor,
}) => {
  const router = useNavigate();
  const [deleteDialogOpened, setIsDeleteDialogOpened] =
    useState<boolean>(false);

  const onDeleteClick = () => {
    setIsDeleteDialogOpened(true);
  };

  const onDeleteDistributorClick = async () => {
    onDeleteDistributor(distributor.id);
  };

  const onEditDistributorClick = (distributorId: string) => {
    router(`/distributors/edit/${distributorId}`);
  };

  return (
    <>
      <TableRow className="cursor-pointer">
        <TableCell className="max-sm:hidden">{distributor.name}</TableCell>
        <TableCell className="max-sm:hidden">{distributor.place}</TableCell>
        <TableCell className="text-wrap flex-wrap break-words break-all whitespace-normal">
          {distributor.email}
        </TableCell>
        <TableCell className="max-lg:hidden">
          {distributor.phoneNumber}
        </TableCell>
        <TableCell className="max-lg:hidden">
          {distributor.number_of_users}
        </TableCell>
        <TableCell className="max-xl:hidden">
          {formatCreationDate(new Date(distributor.createdAt))}
        </TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => onEditDistributorClick(distributor.id)}
              >
                Izmeni distributera
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:text-red-500 cursor-pointer"
                onClick={onDeleteClick}
              >
                Obriši distributera
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete distributera?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati distributera."
        onContinueClick={onDeleteDistributorClick}
        isDialogOpen={deleteDialogOpened}
        setIsDialogOpen={setIsDeleteDialogOpened}
      />
    </>
  );
};

export default DistributorTableRow;
