import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import { CreateFERTDeviceFormData } from '@/components/forms/CreateFERTDeviceForm.tsx';
import { CreateSensDeviceFormData } from '@/components/forms/CreateSensDeviceForm.tsx';
import { CreateStartDeviceFormData } from '@/components/forms/CreateStartDeviceForm.tsx';
import { EditFERTDeviceFormData } from '@/components/forms/EditFERTDeviceForm.tsx';
import { EditSensDeviceFormData } from '@/components/forms/EditSensDeviceForm.tsx';
import { EditStartDeviceFormData } from '@/components/forms/EditStartDeviceForm.tsx';
import {
  AdditionalSensorValuesFERT,
  CreateFERTDeviceData,
  CreateSensDevice,
  CreateStartDeviceData,
  FERTDevice,
  SensDevice,
  StartDevice,
  SupplementForDevice,
} from '@/redux/device/devices.types.ts';

export const itemsForRadioGroup = [
  { value: 'true', text: 'Uključeno' },
  { value: 'false', text: 'Isključeno' },
];

export const communicationModules: DropDownItems[] = [
  {
    value: 'GPRS',
    text: 'GPRS',
  },
  { value: 'LTE', text: 'LTE' },
  { value: 'LoRa', text: 'LoRa' },
];

export const sensDeviceTypes: DropDownItems[] = [
  {
    value: '30',
    text: '30 cm',
  },
  { value: '60', text: '60 cm' },
  { value: '90', text: '90 cm' },
];

export const flowMeterTypes: DropDownItems[] = [
  { text: '(33imp/L)', value: '(33imp/L)' },
  { text: 'Interrup', value: 'Interrup' },
  { text: 'Toggling', value: 'Toggling' },
  { text: '10L/impuls', value: '10L/impuls' },
  { text: '100L/impuls', value: '100L/impuls' },
  { text: '1000L/impuls', value: '1000L/impuls' },
];

export const batteryTypesSENS: DropDownItems[] = [
  {
    text: 'Solarni panel',
    value: 'Solarni panel',
  },
  {
    text: 'Li-Ion Battery + Solar',
    value: 'Li-Ion Battery + Solar',
  },
  {
    text: 'Lead + Solar',
    value: 'Lead + Solar',
  },
  {
    text: '12V DC Adapter',
    value: '12V DC Adapter',
  },
];

export const airTempAndHumidTypes = [
  {
    text: 'BGT',
    value: 'BGT',
  },
  {
    text: 'SHT1',
    value: 'SHT1',
  },
];

export const transformFERTDeviceData = (
  data: CreateFERTDeviceFormData
): CreateFERTDeviceData => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    communicationModule: data.communicationModule,
    pHFactorK: Number(data.pHFactorK),
    pHFactorM: Number(data.pHFactorM),
    supplementOne: data.supplementOne === 'true',
    supplementTwo: data.supplementTwo === 'true',
    supplementThree: data.supplementThree === 'true',
    supplementFour: data.supplementFour === 'true',
    supplementFive: data.supplementFive === 'true',
    supplementMixer: data.supplementMixer === 'true',
    ecSensor: data.ecSensor === 'true',
    ecFactorK: Number(data.ecFactorK),
    flowMeter: data.flowMeter === 'true',
    notificationForWrongFlowMeter: JSON.parse(
      data.notificationForWrongFlowMeter
    ),
    pressureSensor: data.pressureSensor === 'true',
    waterLevelSensor: data.waterLevelSensor === 'true',
    pHWater: data.pHWater === 'true',
  };
};

export const transformStartDeviceData = (
  data: CreateStartDeviceFormData
): CreateStartDeviceData => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    humidityOne: data.humidityOne === 'true',
    humidityTwo: data.humidityTwo === 'true',
    supplementMixer: data.supplementMixer === 'true',
    flowMeter: data.flowMeter === 'true',
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
  };
};

export const transformStartDeviceForForm = (
  device: StartDevice
): EditStartDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    humidityOne: device.humidity.humidityOne.toString(),
    humidityTwo: device.humidity.humidityTwo.toString(),
    supplementMixer: device.additionalSensorValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorValues.flowMeter.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorValues.pressureSensor.toString(),
  };
};

export const transformEditStartDeviceData = (data: {
  device: StartDevice;
  deviceToEdit: EditStartDeviceFormData;
}): StartDevice => {
  const { device, deviceToEdit } = data;
  const supplementMixer = deviceToEdit.supplementMixer === 'true';

  return {
    id: device.id,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    zonesForDevice: deviceToEdit.zonesForDevice.map((zone) => Number(zone)),
    humidity: {
      humidityOne: deviceToEdit.humidityOne === 'true',
      humidityTwo: deviceToEdit.humidityTwo === 'true',
    },
    additionalSensorValues: {
      supplementMixer: deviceToEdit.supplementMixer === 'true',
      flowMeter: deviceToEdit.flowMeter === 'true',
      notificationForWrongFlowMeter:
        deviceToEdit.notificationForWrongFlowMeter === 'true',
      pressureSensor: deviceToEdit.pressureSensor === 'true',
    },
    additionalFields: {
      rev: device.additionalFields.rev,
      acRelay: deviceToEdit.zonesForDevice.length,
      mixerRelay24: supplementMixer,
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      flowMeterType:
        deviceToEdit.flowMeter === 'true'
          ? device.additionalFields.flowMeterType
          : '',
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
    },
    commentOnEdit: device.commentOnEdit,
  };
};

export const transformFERTDeviceForForm = (
  device: FERTDevice
): EditFERTDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    communicationModule: device.communicationModule,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    supplementMixer: device.additionalSensorsValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorsValues.flowMeter.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorsValues.pressureSensor.toString(),
    pHFactorK: device.pHFactorK.toString(),
    pHFactorM: device.pHFactorM.toString(),
    supplementOne: device.supplement.supplementOne.toString(),
    supplementTwo: device.supplement.supplementTwo.toString(),
    supplementThree: device.supplement.supplementThree.toString(),
    supplementFour: device.supplement.supplementFour.toString(),
    supplementFive: device.supplement.supplementFive.toString(),
    ecSensor: device.additionalSensorsValues.ecSensor.toString(),
    ecFactorK: device.additionalSensorsValues.ecFactorK.toString(),
    waterLevelSensor:
      device.additionalSensorsValues.waterLevelSensor.toString(),
    pHWater: device.additionalFields.pHWater.toString(),
  };
};

export const transformEditFERTDeviceData = (data: {
  device: FERTDevice;
  deviceToEdit: EditFERTDeviceFormData;
}): FERTDevice => {
  const { device, deviceToEdit } = data;

  const supplement: SupplementForDevice = {
    supplementOne: deviceToEdit.supplementOne === 'true',
    supplementTwo: deviceToEdit.supplementTwo === 'true',
    supplementThree: deviceToEdit.supplementThree === 'true',
    supplementFour: deviceToEdit.supplementFour === 'true',
    supplementFive: deviceToEdit.supplementFive === 'true',
  };

  const additionalSensorsValues: AdditionalSensorValuesFERT = {
    supplementMixer: deviceToEdit.supplementMixer === 'true',
    ecSensor: deviceToEdit.ecSensor === 'true',
    waterLevelSensor: deviceToEdit.waterLevelSensor === 'true',
    pressureSensor: deviceToEdit.pressureSensor === 'true',
    notificationForWrongFlowMeter:
      deviceToEdit.notificationForWrongFlowMeter === 'true',
    flowMeter: deviceToEdit.flowMeter === 'true',
    ecFactorK: Number(deviceToEdit.ecFactorK),
  };

  return {
    id: device.id,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    communicationModule: deviceToEdit.communicationModule,
    pHFactorK: Number(deviceToEdit.pHFactorK),
    pHFactorM: Number(deviceToEdit.pHFactorM),
    zonesForDevice: deviceToEdit.zonesForDevice.map((zone) => Number(zone)),
    additionalFields: {
      rev: device.additionalFields.rev,
      acRelay: deviceToEdit.zonesForDevice.length,
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      pHControl: supplement.supplementOne,
      ecControl:
        supplement.supplementTwo ||
        supplement.supplementThree ||
        supplement.supplementFour ||
        supplement.supplementFive,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2:
        supplement.supplementOne ||
        supplement.supplementTwo ||
        supplement.supplementThree ||
        supplement.supplementFour ||
        supplement.supplementFive,
      pHWater: deviceToEdit.pHWater === 'true',
      flowMeterType: deviceToEdit.flowMeter
        ? device.additionalFields.flowMeterType
        : '',
    },
    commentOnEdit: device.commentOnEdit,
    supplement,
    additionalSensorsValues,
  };
};

export const transformSensDeviceData = (
  data: CreateSensDeviceFormData
): CreateSensDevice => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    sensSensorType: Number(data.sensSensorType),
    standbyModeTime: Number(data.standbyModeTime),
    humidityOne: data.humidityOne === 'true',
    humidityTwo: data.humidityTwo === 'true',
    flowMeter: data.flowMeter === 'true',
    soilTemperature: data.soilTemperature === 'true',
    soilSalinity: data.soilSalinity === 'true',
    soilPH: data.soilPH === 'true',
    airTemperature: data.airTemperature === 'true',
    airHumidity: data.airHumidity === 'true',
    windDirection: data.windDirection === 'true',
    windSpeed: data.windSpeed === 'true',
    solarRadiation: data.solarRadiation === 'true',
    amountOfPrecipitation: data.amountOfPrecipitation === 'true',
    batteryVoltage: data.batteryVoltage === 'true',
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
    batteryType: data.batteryType,
  };
};

export const transformSensDeviceForForm = (
  device: SensDevice
): EditSensDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    standbyModeTime: device.standbyModeTime.toString(),
    sensSensorType: device.sensSensorType.toString(),
    humidityOne: device.humidity.humidityOne.toString(),
    humidityTwo: device.humidity.humidityTwo.toString(),
    flowMeter: device.additionalSensorsValues.flowMeter.toString(),
    soilTemperature: device.additionalSensorsValues.soilTemperature.toString(),
    soilSalinity: device.additionalSensorsValues.soilSalinity.toString(),
    soilPH: device.additionalSensorsValues.soilPH.toString(),
    airTemperature: device.additionalSensorsValues.airTemperature.toString(),
    airHumidity: device.additionalSensorsValues.airHumidity.toString(),
    windDirection: device.additionalSensorsValues.windDirection.toString(),
    windSpeed: device.additionalSensorsValues.windSpeed.toString(),
    solarRadiation: device.additionalSensorsValues.solarRadiation.toString(),
    amountOfPrecipitation:
      device.additionalSensorsValues.amountOfPrecipitation.toString(),
    batteryVoltage: device.additionalSensorsValues.batteryVoltage.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorsValues.pressureSensor.toString(),
    batteryType: device.additionalFields.batteryType,
  };
};

export const transformEditSensDeviceData = (data: {
  device: SensDevice;
  deviceToEdit: EditSensDeviceFormData;
}): SensDevice => {
  const { device, deviceToEdit } = data;
  const additionalSensorsValues = {
    flowMeter: deviceToEdit.flowMeter === 'true',
    pressureSensor: deviceToEdit.pressureSensor === 'true',
    airHumidity: deviceToEdit.airHumidity === 'true',
    soilPH: deviceToEdit.soilPH === 'true',
    notificationForWrongFlowMeter:
      deviceToEdit.notificationForWrongFlowMeter === 'true',
    airTemperature: deviceToEdit.airTemperature === 'true',
    amountOfPrecipitation: deviceToEdit.amountOfPrecipitation === 'true',
    batteryVoltage: deviceToEdit.batteryVoltage === 'true',
    soilSalinity: deviceToEdit.soilSalinity === 'true',
    soilTemperature: deviceToEdit.soilTemperature === 'true',
    solarRadiation: deviceToEdit.solarRadiation === 'true',
    windDirection: deviceToEdit.windDirection === 'true',
    windSpeed: deviceToEdit.windSpeed === 'true',
  };
  const sensSensorType: number = Number(deviceToEdit.sensSensorType);
  const humidity = {
    humidityOne: deviceToEdit.humidityOne === 'true',
    humidityTwo: deviceToEdit.humidityTwo === 'true',
  };

  return {
    id: device.id,
    parcelId: deviceToEdit.parcelId,
    name: deviceToEdit.name,
    color: deviceToEdit.color,
    serialNumber: device.serialNumber ? device.serialNumber : '',
    standbyModeTime: Number(deviceToEdit.standbyModeTime),
    zonesForDevice: deviceToEdit.zonesForDevice.map((zone) => Number(zone)),
    additionalFields: {
      rev: device.additionalFields.rev,
      dcRelay: deviceToEdit.zonesForDevice.length,
      sentekTH30:
        additionalSensorsValues.soilTemperature &&
        sensSensorType === 30 &&
        !additionalSensorsValues.soilSalinity,
      sentekTHS30:
        additionalSensorsValues.soilTemperature &&
        additionalSensorsValues.soilSalinity &&
        sensSensorType === 30,
      sentekTH60:
        additionalSensorsValues.soilTemperature &&
        sensSensorType === 60 &&
        !additionalSensorsValues.soilSalinity,
      sentekTHS60:
        additionalSensorsValues.soilTemperature &&
        additionalSensorsValues.soilSalinity &&
        sensSensorType === 60,
      sentekTH90:
        additionalSensorsValues.soilTemperature &&
        sensSensorType === 90 &&
        !additionalSensorsValues.soilSalinity,
      sentekTHS90:
        additionalSensorsValues.soilTemperature &&
        additionalSensorsValues.soilSalinity &&
        sensSensorType === 90,
      airTempAndHumidType:
        additionalSensorsValues.airTemperature &&
        additionalSensorsValues.airHumidity
          ? device.additionalFields.airTempAndHumidType
          : '',
      dekagonHS1: humidity.humidityOne,
      dekagonHS2: humidity.humidityTwo,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: deviceToEdit.batteryType,
      flowMeterType:
        deviceToEdit.flowMeter === 'true'
          ? device.additionalFields.flowMeterType
          : '',
    },
    additionalSensorsValues,
    sensSensorType,
    humidity,
    commentOnEdit: device.commentOnEdit,
  };
};

const compareByValues = (a: any, b: any) => {
  return a.value - b.value;
};

export const formAvailableZonesContent = (
  device: StartDevice | FERTDevice | SensDevice,
  availableZones: number[]
) => {
  const transformedAvailableZones = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  if (!device) {
    return transformedAvailableZones;
  }

  const deviceZones = device.zonesForDevice.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  return [...transformedAvailableZones, ...deviceZones].sort(compareByValues);
};
