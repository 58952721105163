import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import StartDeviceHumidityForm from '@/components/forms/devices/StartDeviceHumidityForm.tsx';
import StartDeviceInfoForm from '@/components/forms/devices/StartDeviceInfoForm.tsx';
import StartDeviceSensorsForm from '@/components/forms/devices/StartDeviceSensorsForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createStartDevice } from '@/redux/device/devices.actions.ts';
import { getAvailableZones } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createStartDeviceSchema } from '@/zod/device.types.zod.ts';

export type CreateStartDeviceFormData = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: string[];
  humidityOne: string;
  humidityTwo: string;
  supplementMixer: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
};

const CreateStartDeviceForm: FC = () => {
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(createStartDeviceSchema),
    defaultValues: {
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      zonesForDevice: [],
      humidityOne: 'false',
      humidityTwo: 'false',
      supplementMixer: 'true',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const multiSelectData = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(-1);
  };

  const onSubmit = async (data: CreateStartDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(createStartDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno dodat uredjaj');
    router(-1);
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Accordion
          defaultValue="infoForm"
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <StartDeviceInfoForm
                form={form}
                multiSelectData={multiSelectData}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <StartDeviceHumidityForm form={form} />
              <StartDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj uredjaj"
        />
      </form>
    </Form>
  );
};

export default CreateStartDeviceForm;
