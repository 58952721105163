import { FC } from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
} from '@/components/ui/select.tsx';

export type DropDownItems = {
  value: string;
  text: string;
};

type CustomFormDropDownProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  formItemStyle?: string;
  labelStyle?: string;
  placeholder?: string;
  items: DropDownItems[];
  onChangeFunction?: (value: string) => void;
  isDisabled?: boolean;
};

const CustomFormDropDown: FC<CustomFormDropDownProps> = ({
  customForm,
  name,
  labelText,
  formItemStyle,
  labelStyle,
  placeholder,
  items,
  onChangeFunction,
  isDisabled,
}) => {
  const onSelectionChange = (
    e: string,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    field.onChange(e);

    if (onChangeFunction) {
      onChangeFunction(e);
    }
  };

  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <Select
            onValueChange={(e) => onSelectionChange(e, field)}
            value={field.value}
            disabled={isDisabled}
          >
            <FormControl className="focus:outline-none">
              <SelectTrigger className="w-full">
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent
              ref={(ref) => {
                if (!ref) {
                  return;
                }

                // eslint-disable-next-line no-param-reassign
                ref.ontouchstart = (e) => {
                  e.preventDefault();
                };
              }}
            >
              {items.map((item, index) => (
                <SelectItem
                  key={`dropdown-${name}-${item.value}-${index + 1}`}
                  value={item.value}
                >
                  {item.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CustomFormDropDown;
