import { FC } from 'react';

import DeliveryPageContent from '@/components/layout/delivery/DeliveryPageContent.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const DeliveryPage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40 overflow-x-auto">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 overflow-x-auto">
          <DeliveryPageContent />
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
