import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import UserAddressForm from '@/components/forms/user/UserAddressForm.tsx';
import UserCompanyForm from '@/components/forms/user/UserCompanyForm.tsx';
import UserInfoForm from '@/components/forms/user/UserInfoForm.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { Spinner } from '@/components/ui/spinner.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createOrganization } from '@/redux/organizations/organizations.actions.ts';
import { CreateOrganizationResponse } from '@/redux/organizations/organizations.types.ts';
import { createUser } from '@/redux/users/users.actions.ts';
import { CreateUserResponse } from '@/redux/users/users.types.ts';
import { createUserSchema } from '@/zod/user.types.zod.ts';

export type CreateUserFormData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
  nameOfTheCompany: string;
  pib: string;
  mbr: string;
  dateOfPayment: Date;
  differentBillingAddress: boolean;
  billingAddress: string;
  billingPlace: string;
  billingCountry: string;
  wayOfInforming: string;
  organizationId: string;
};

const AddUserForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      phoneNumber: '',
      address: '',
      place: '',
      country: '',
      nameOfTheCompany: '',
      pib: '',
      mbr: '',
      dateOfPayment: new Date(),
      differentBillingAddress: false,
      billingAddress: '',
      billingPlace: '',
      billingCountry: '',
      wayOfInforming: '',
      organizationId: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const handleErrorResponse = (
    response: CreateUserResponse | CreateOrganizationResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(-1);
  };

  const onSubmit = async (data: CreateUserFormData) => {
    setRequestSent(true);
    if (!data.organizationId) {
      const responseOrganization = await dispatch(
        createOrganization(`${data.name} ${data.surname}`)
      ).unwrap();
      if (!responseOrganization.success) {
        setRequestSent(false);
        handleErrorResponse(responseOrganization);
        return;
      }

      // eslint-disable-next-line no-param-reassign
      data.organizationId = responseOrganization.content.id;
    }
    // @ts-ignore
    const response = await dispatch(createUser(data)).unwrap();
    setRequestSent(false);

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Korisnik je uspešno kreiran');
    router(-1);
  };

  if (requestSent) {
    return (
      <div className="flex flex-col bg-[#85b4f2] bg-opacity-10 w-full items-center justify-center">
        <Spinner size="medium" className="text-[#007bff]" />
      </div>
    );
  }

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Accordion
          defaultValue="infoForm"
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovne informacije</AccordionTrigger>
            <AccordionContent>
              <UserInfoForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="addressForm">
            <AccordionTrigger>Podaci o adresi</AccordionTrigger>
            <AccordionContent>
              <UserAddressForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="companyForm">
            <AccordionTrigger>Podaci o kompaniji</AccordionTrigger>
            <AccordionContent>
              <UserCompanyForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj korisnika"
        />
      </form>
    </Form>
  );
};

export default AddUserForm;
