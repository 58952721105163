import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import BillingAddressForm from '@/components/forms/user/BillingAddressForm.tsx';

type UserAddressFormProps = {
  form: UseFormReturn<any>;
};

const UserAddressForm: FC<UserAddressFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="address"
        labelText="Adresa"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="place"
        labelText="Mesto"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="country"
        labelText="Drzava"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <BillingAddressForm
        form={form}
        isFormShown={form.getValues('differentBillingAddress' as never)}
      />
    </>
  );
};

export default UserAddressForm;
