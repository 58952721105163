import { FC } from 'react';

import EditDistributorInfoForm from '@/components/forms/EditDistributorInfoForm.tsx';
import EditDistributorsUserSection from '@/components/layout/distributors/EditDistributorsUserSection.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';

export type EditDistributorFormData = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
};

enum EditDistributorTab {
  INFO = 'info',
  USERS = 'users',
}

const EditDistributorTabs: FC = () => {
  return (
    <Tabs defaultValue={EditDistributorTab.INFO}>
      <TabsList>
        <TabsTrigger
          value={EditDistributorTab.INFO}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
        >
          Info
        </TabsTrigger>
        <TabsTrigger
          value={EditDistributorTab.USERS}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
        >
          Korisnici
        </TabsTrigger>
      </TabsList>
      <TabsContent value={EditDistributorTab.INFO}>
        <EditDistributorInfoForm />
      </TabsContent>
      <TabsContent value={EditDistributorTab.USERS}>
        <EditDistributorsUserSection />
      </TabsContent>
    </Tabs>
  );
};

export default EditDistributorTabs;
