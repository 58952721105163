import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MultiSelectItem } from '@/components/controls/CustomFormMultiSelect.tsx';
import SensDeviceInfoForm from '@/components/forms/devices/SensDeviceInfoForm.tsx';
import SensDeviceSensorsForm from '@/components/forms/devices/SensDeviceSensorsForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { updateSensDevice } from '@/redux/device/devices.actions.ts';
import {
  getAvailableZones,
  getSensDeviceForEdit,
} from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { formAvailableZonesContent } from '@/utils/device.util.ts';
import { editSensDeviceSchema } from '@/zod/device.types.zod.ts';

export type EditSensDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: string[];
  standbyModeTime: string;
  sensSensorType: string;
  humidityOne: string;
  humidityTwo: string;
  flowMeter: string;
  soilTemperature: string;
  soilSalinity: string;
  soilPH: string;
  airTemperature: string;
  airHumidity: string;
  windDirection: string;
  windSpeed: string;
  solarRadiation: string;
  amountOfPrecipitation: string;
  batteryVoltage: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  batteryType: string;
};

type EditSensDeviceFormProps = {
  transformedSensDevice: EditSensDeviceFormData;
};

const EditSensDeviceForm: FC<EditSensDeviceFormProps> = ({
  transformedSensDevice,
}) => {
  const device = useAppSelector(getSensDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editSensDeviceSchema),
    defaultValues: {
      id: '',
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      zonesForDevice: [],
      standbyModeTime: '0',
      sensSensorType: '30',
      humidityOne: 'true',
      humidityTwo: 'false',
      flowMeter: 'true',
      soilTemperature: 'true',
      soilSalinity: 'true',
      soilPH: 'true',
      airTemperature: 'false',
      airHumidity: 'false',
      windDirection: 'false',
      windSpeed: 'false',
      solarRadiation: 'false',
      amountOfPrecipitation: 'false',
      batteryVoltage: 'true',
      notificationForWrongFlowMeter: 'false',
      pressureSensor: 'false',
      batteryType: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const [multiSelectData, setMultiSelectData] = useState<MultiSelectItem[]>([]);
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditSensDeviceFormData) => {
    const dataToSend = {
      device,
      deviceToEdit: data,
    };

    // @ts-ignore
    const response = await dispatch(updateSensDevice(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  useEffect(() => {
    if (transformedSensDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedSensDevice.name,
        color: transformedSensDevice.color,
        zonesForDevice: transformedSensDevice.zonesForDevice,
        standbyModeTime: transformedSensDevice.standbyModeTime,
        sensSensorType: transformedSensDevice.sensSensorType,
        humidityOne: transformedSensDevice.humidityOne,
        humidityTwo: transformedSensDevice.humidityTwo,
        flowMeter: transformedSensDevice.flowMeter,
        soilTemperature: transformedSensDevice.soilTemperature,
        soilSalinity: transformedSensDevice.soilSalinity,
        soilPH: transformedSensDevice.soilPH,
        airTemperature: transformedSensDevice.airTemperature,
        airHumidity: transformedSensDevice.airHumidity,
        windDirection: transformedSensDevice.windDirection,
        windSpeed: transformedSensDevice.windSpeed,
        solarRadiation: transformedSensDevice.solarRadiation,
        amountOfPrecipitation: transformedSensDevice.amountOfPrecipitation,
        batteryVoltage: transformedSensDevice.batteryVoltage,
        notificationForWrongFlowMeter:
          transformedSensDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedSensDevice.pressureSensor,
        batteryType: transformedSensDevice.batteryType,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedSensDevice]);

  useEffect(() => {
    setMultiSelectData(formAvailableZonesContent(device, availableZones));
  }, [device, availableZones]);

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Accordion
          defaultValue="infoForm"
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <SensDeviceInfoForm
                form={form}
                multiSelectData={multiSelectData}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <SensDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Izmeni uredjaj"
        />
      </form>
    </Form>
  );
};

export default EditSensDeviceForm;
