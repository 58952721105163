import { FC } from 'react';

import { Button } from '@/components/ui/button.tsx';
import { TableHead, TableHeader, TableRow } from '@/components/ui/table.tsx';

export type TableHeaderType = {
  id: string;
  text: string;
  className: string;
  isSortDisabled?: boolean;
};

type CustomTableHeaderProps = {
  tableName: string;
  tableHeaders: TableHeaderType[];
};

const NotSubscribedCustomTableHeader: FC<CustomTableHeaderProps> = ({
  tableName,
  tableHeaders,
}) => {
  return (
    <TableHeader>
      <TableRow>
        {tableHeaders.map((tableHeader, index) => (
          <TableHead
            key={`${tableName}-table-header-${tableHeader.id}-${index + 1}`}
            className={tableHeader.className}
          >
            <Button variant="ghost">{tableHeader.text}</Button>
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
  );
};
export default NotSubscribedCustomTableHeader;
