import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.selectors.ts';

type CreateParcelInfoFormProps = {
  form: UseFormReturn<any>;
};

const CreateParcelInfoForm: FC<CreateParcelInfoFormProps> = ({ form }) => {
  const organizations = useAppSelector(getOrganizations);
  const organizationsComboboxContent: ComboboxItemType[] = organizations.map(
    (organization) => {
      return {
        value: organization.id,
        label: organization.name,
      };
    }
  );

  return (
    <>
      <CustomFormCombobox
        customForm={form}
        name="organizationId"
        labelText="Organizacija"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={organizationsComboboxContent}
        placeholder="Odaberite organizaciju"
      />
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Naziv parcele"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="numberOfZones"
        labelText="Broj zona"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="place"
        labelText="Mesto"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="country"
        labelText="Država"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="weatherForecast"
        labelText="Vremenska prognoza"
        formItemStyle="flex flex-col w-full pb-5 mt-2 sm:mt-5 px-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full w-[500px] px-3 py-2 justify-between"
        items={[{ value: 'Novi Sad', label: 'Novi Sad' }]}
        placeholder="Odaberite vremensku prognozu"
      />
      <CustomFormInput
        customForm={form}
        name="simCardNumber"
        labelText="Broj SIM kartice"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
    </>
  );
};

export default CreateParcelInfoForm;
