import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.selectors.ts';

type UserInfoFormProps = {
  form: UseFormReturn<any>;
  isEdit?: boolean;
};

const UserInfoForm: FC<UserInfoFormProps> = ({ form, isEdit }) => {
  const comboboxContent: ComboboxItemType[] = [
    { value: 'Email', label: 'Email' },
    { value: 'SMS', label: 'SMS' },
  ];

  const organizations = useAppSelector(getOrganizations);
  const organizationsComboboxContent: ComboboxItemType[] = organizations.map(
    (organization) => {
      return {
        value: organization.id,
        label: organization.name,
      };
    }
  );

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="surname"
        labelText="Prezime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="email"
        labelText="Email"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-sm:w-full md:w-[500px]"
        type="text"
      />
      {!isEdit ? (
        <CustomFormInput
          customForm={form}
          name="password"
          labelText="Lozinka"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-sm:w-full md:w-[500px]"
          type="password"
        />
      ) : null}
      <CustomFormInput
        customForm={form}
        name="phoneNumber"
        labelText="Telefon"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-sm:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="organizationId"
        labelText="Organizacija"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={organizationsComboboxContent}
        placeholder="Odaberite organizaciju"
      />
      <CustomFormCombobox
        customForm={form}
        name="wayOfInforming"
        labelText="Način informisanja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={comboboxContent}
        placeholder="Odaberite način informisanja"
      />
    </>
  );
};

export default UserInfoForm;
