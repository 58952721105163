import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateFERTDeviceForm from '@/components/forms/CreateFERTDeviceForm.tsx';
import CreateSensDeviceForm from '@/components/forms/CreateSensDeviceForm.tsx';
import CreateStartDeviceForm from '@/components/forms/CreateStartDeviceForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAvailableZonesForParcel } from '@/redux/device/devices.actions.ts';
import {
  deviceTypeOptions,
  deviceTypes,
  GetAvailableZonesResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type SelectDeviceTypeSectionProps = {
  onDeviceTypeChange: (value: string) => void;
};

const SelectDeviceTypeSection: FC<SelectDeviceTypeSectionProps> = ({
  onDeviceTypeChange,
}) => {
  return (
    <Select onValueChange={onDeviceTypeChange}>
      <SelectTrigger className="w-[180px] font-light">
        <SelectValue
          placeholder="Tip uredjaja"
          className="font-light text-black"
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {deviceTypeOptions.map((deviceType, idx) => (
            <SelectItem
              key={`device-type-${deviceType.value}-${idx + 1}`}
              value={deviceType.value}
              className="text-black font-light"
            >
              {deviceType.text}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const CreateDeviceContent: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('');

  const onDeviceTypeChange = (value: string) => {
    setSelectedDeviceType(value);
  };

  const handleErrorResponse = (response: GetAvailableZonesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchAvailableZones = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAvailableZonesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAvailableZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          <p className="max-sm:text-start text-center">Novi uredjaj</p>
          <SelectDeviceTypeSection onDeviceTypeChange={onDeviceTypeChange} />
        </CardTitle>
        <CardContent className="p-0">
          {selectedDeviceType === deviceTypes.FERT && <CreateFERTDeviceForm />}
          {selectedDeviceType === deviceTypes.START && (
            <CreateStartDeviceForm />
          )}
          {selectedDeviceType === deviceTypes.SENS && <CreateSensDeviceForm />}
        </CardContent>
      </CardHeader>
    </Card>
  );
};

export default CreateDeviceContent;
