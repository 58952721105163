import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateDistributorFormData } from '@/components/forms/AddDistributorForm.tsx';
import { AddUserForDistributorFormData } from '@/components/forms/AddUserForDistributorForm.tsx';
import { EditDistributorFormData } from '@/components/layout/distributors/EditDistributorTabs.tsx';
import {
  addDistributor,
  setDistributors,
  setFilteredNumberOfDistributors,
  setFilteredNumberOfUsersForDistributors,
  setTotalNumberOfDistributors,
  setTotalNumberOfUsersForDistributors,
  setUpdatedDistributor,
  setUsersForSelectedDistributor,
  updateTotalNumberOfUsersForDistributor,
} from '@/redux/distributors/distributors.slice.ts';
import {
  CreateDistributorResponse,
  GetDistributorsResponse,
} from '@/redux/distributors/distributors.types.ts';
import {
  addDropdownUser,
  removeDropdownUser,
} from '@/redux/users/users.slice.ts';
import {
  GetUsersResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getFilteredDistributors = createAsyncThunk<
  GetDistributorsResponse,
  string
>('admin/get-distributors', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetDistributorsResponse>(
      `/admin/distributor${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setDistributors(axiosResponse.data.content.fetchedDistributors));
    dispatch(
      setTotalNumberOfDistributors(
        Number(axiosResponse.data.content.totalCount)
      )
    );
    dispatch(
      setFilteredNumberOfDistributors(
        Number(axiosResponse.data.content.filteredCount)
      )
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const createDistributor = createAsyncThunk<
  CreateDistributorResponse,
  CreateDistributorFormData
>('admin/create-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDistributorResponse>(
      '/admin/distributor/create-distributor',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(addDistributor(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getDistributorById = createAsyncThunk<CreateDistributorResponse, string>(
  'admin/get-distributor-by-id',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<CreateDistributorResponse>(
        `/admin/distributor/get-one?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateDistributor = createAsyncThunk<
  CreateDistributorResponse,
  EditDistributorFormData
>('admin/edit-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.put<CreateDistributorResponse>(
      '/admin/distributor/update',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setUpdatedDistributor(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const setDistributorForUser = createAsyncThunk<
  UpdateUserResponse,
  AddUserForDistributorFormData
>('admin/set-user-for-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<UpdateUserResponse>(
      '/admin/distributor/update/set-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(removeDropdownUser(reqData.userId));
    dispatch(updateTotalNumberOfUsersForDistributor(1));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getFilteredUsersForDistributor = createAsyncThunk<
  GetUsersResponse,
  string
>('admin/get-users-for-distributor', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetUsersResponse>(
      `/admin/distributor/get-distributor-users${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(
      setUsersForSelectedDistributor(axiosResponse.data.content.fetchedUsers)
    );
    dispatch(
      setTotalNumberOfUsersForDistributors(
        axiosResponse.data.content.totalCount
      )
    );
    dispatch(
      setFilteredNumberOfUsersForDistributors(
        axiosResponse.data.content.filteredCount
      )
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const removeDistributorForUser = createAsyncThunk<
  UpdateUserResponse,
  { userId: string }
>('admin/remove-distributor-for-user', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<UpdateUserResponse>(
      '/admin/distributor/remove-distributor-for-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(addDropdownUser(axiosResponse.data.content));
    dispatch(updateTotalNumberOfUsersForDistributor(-1));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteDistributor = createAsyncThunk<
  CreateDistributorResponse,
  { distributorId: string }
>('admin/delete-distributor', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.delete<CreateDistributorResponse>(
      `/admin/distributor/delete-distributor?distributorId=${reqData.distributorId}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  createDistributor,
  getFilteredDistributors,
  getDistributorById,
  updateDistributor,
  setDistributorForUser,
  getFilteredUsersForDistributor,
  removeDistributorForUser,
  deleteDistributor,
};
