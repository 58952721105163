import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { EditDistributorFormData } from '@/components/layout/distributors/EditDistributorTabs.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import {
  getDistributorById,
  updateDistributor,
} from '@/redux/distributors/distributors.actions.ts';
import {
  CreateDistributorResponse,
  Distributor,
} from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { editDistributorSchema } from '@/zod/distributor.types.zod.ts';

type EditDistributorInfoButtonSectionProps = {
  onGiveUpClick: () => void;
};

const EditDistributorInfoButtonSection: FC<
  EditDistributorInfoButtonSectionProps
> = ({ onGiveUpClick }) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <div className="max-md:w-full flex flex-row gap-5 flex-wrap">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          type="button"
          onClick={onGiveUpClick}
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Izmeni distributera
        </Button>
      </div>
    </div>
  );
};

const EditDistributorInfoForm: FC = () => {
  const params = useParams();
  const [existingDistributor, setExistingDistributor] = useState<Distributor>({
    id: '',
    address: '',
    country: '',
    createdAt: '',
    createdBy: '',
    email: '',
    name: '',
    phoneNumber: '',
    place: '',
  });
  const editForm = useForm({
    resolver: zodResolver(editDistributorSchema),
    defaultValues: {
      ...existingDistributor,
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateDistributorResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditDistributorFormData) => {
    // @ts-ignore
    const response = await dispatch(updateDistributor(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Distributer je uspešno izmenjen');
    onGiveUpClick();
  };

  useEffect(() => {
    const fetchDistributor = async () => {
      // @ts-ignore
      const response: CreateDistributorResponse = await dispatch(
        getDistributorById(params.id)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }

      setExistingDistributor(response.content);
    };

    fetchDistributor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    editForm.setValue('id', existingDistributor.id);
    editForm.setValue('name', existingDistributor.name);
    editForm.setValue('email', existingDistributor.email);
    editForm.setValue('phoneNumber', existingDistributor.phoneNumber);
    editForm.setValue('place', existingDistributor.place);
    editForm.setValue('address', existingDistributor.address);
    editForm.setValue('country', existingDistributor.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingDistributor]);

  return (
    <Card x-chunk="card-distributor-info">
      <CardHeader>
        <CardTitle>Izmeni distributera</CardTitle>
      </CardHeader>
      <CardContent>
        <Form
          reset={editForm.reset}
          formState={editForm.formState}
          clearErrors={editForm.clearErrors}
          control={editForm.control}
          getFieldState={editForm.getFieldState}
          getValues={editForm.getValues}
          handleSubmit={editForm.handleSubmit}
          register={editForm.register}
          resetField={editForm.resetField}
          setError={editForm.setError}
          setFocus={editForm.setFocus}
          setValue={editForm.setValue}
          trigger={editForm.trigger}
          unregister={editForm.unregister}
          watch={editForm.watch}
        >
          <form onSubmit={editForm.handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <CustomFormInput
                customForm={editForm}
                name="name"
                labelText="Naziv"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <CustomFormInput
                customForm={editForm}
                name="email"
                labelText="Email"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <CustomFormInput
                customForm={editForm}
                name="phoneNumber"
                labelText="Telefon"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <CustomFormInput
                customForm={editForm}
                name="address"
                labelText="Adresa"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <CustomFormInput
                customForm={editForm}
                name="place"
                labelText="Mesto"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <CustomFormInput
                customForm={editForm}
                name="country"
                labelText="Drzava"
                formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
                labelStyle="max-sm:w-full sm:w-[300px]"
                divStyle="max-md:w-full md:w-[500px]"
                type="text"
              />
              <EditDistributorInfoButtonSection onGiveUpClick={onGiveUpClick} />
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default EditDistributorInfoForm;
