import { FC } from 'react';

import DeliverySTARTDeviceForm from '@/components/forms/delivery/DeliverySTARTDeviceForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import { DeviceRevision, StartDevice } from '@/redux/device/devices.types.ts';

type STARTDevicesTableProps = {
  userSTARTDevices: StartDevice[];
  revisionsSTART: DeviceRevision[];
};

const STARTDevicesTable: FC<STARTDevicesTableProps> = ({
  userSTARTDevices,
  revisionsSTART,
}) => {
  return (
    <Card x-chunk="user-fert-devices">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          START uređaji
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto w-full">
          <Table className="min-w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[150px]">Device name</TableHead>
                <TableHead className="min-w-[150px]">
                  Device Serial Number
                </TableHead>
                <TableHead className="min-w-[150px]">DID</TableHead>
                <TableHead className="min-w-[150px]">Revision</TableHead>
                <TableHead className="min-w-[100px]">Zone 24V</TableHead>
                <TableHead className="min-w-[140px]">Pump 24Vac</TableHead>
                <TableHead className="min-w-[150px]">
                  Prihrana/Mixer 24Vac
                </TableHead>
                <TableHead className="min-w-[150px]">
                  Dekagon 10HS Sensor 1
                </TableHead>
                <TableHead className="min-w-[150px]">
                  Dekagon 10HS Sensor 2
                </TableHead>
                <TableHead className="min-w-[200px]">FLOW</TableHead>
                <TableHead className="min-w-[130px]">
                  Irigation By time [min]
                </TableHead>
                <TableHead className="min-w-[130px]">
                  Irigation By Volume [m3]
                </TableHead>
                <TableHead className="min-w-[300px]">Comment</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userSTARTDevices?.map((device, index) => (
                <DeliverySTARTDeviceForm
                  device={device}
                  revisionsSTART={revisionsSTART}
                  key={`START-device-table-row-${index + 1}`}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default STARTDevicesTable;
