import { FC } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

type IrrigationInfoCardProps = {
  chunk: string;
  title: string;
  value: string;
};

const IrrigationInfoCard: FC<IrrigationInfoCardProps> = ({
  chunk,
  title,
  value,
}) => {
  return (
    <Card x-chunk={chunk}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
      </CardContent>
    </Card>
  );
};

const IrrigationInfoStatistics: FC = () => {
  return (
    <AccordionItem value="irrigationInfo">
      <AccordionTrigger>Podaci o navodnjavanju parcele</AccordionTrigger>
      <AccordionContent>
        <div className="grid gap-4 lg:grid-cols-4 xl:gap-8 xl:grid-cols-7 md:grid-cols-1 md:mt-5">
          <IrrigationInfoCard
            chunk="number-of-irrigations"
            title="Broj navodnjavanja"
            value="20"
          />
          <IrrigationInfoCard
            chunk="water-usage-from-begining"
            title="Ukupna potrošnja vode od momenta puštanja u rad"
            value="1200 l"
          />
          <IrrigationInfoCard
            chunk="water-usage-for-season"
            title="Ukupna potrošnja vode za sezonu"
            value="1000 l"
          />
          <IrrigationInfoCard
            chunk="water-usage-for-month"
            title="Ukupna potršnja vode za poslednji mesec"
            value="500 l"
          />
          <IrrigationInfoCard
            chunk="water-usage-for-3-days"
            title="Ukupna potršnja vode u poslednja 3 dana"
            value="20 l"
          />
          <IrrigationInfoCard
            chunk="water-usage-for-7-days"
            title="Ukupna potršnja vode u poslednjih 7 dana"
            value="100 l"
          />
          <IrrigationInfoCard
            chunk="water-usage-for-today"
            title="Ukupna potršnja vode u današnjem danu"
            value="5 l"
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default IrrigationInfoStatistics;
