import debounce from 'lodash/debounce';
import { Search } from 'lucide-react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import UserTableRow from '@/components/layout/users/UserTableRow.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getFilteredUsersForDistributor,
  removeDistributorForUser,
} from '@/redux/distributors/distributors.actions.ts';
import {
  getFilteredNumberOfUsersForDistributors,
  getTotalNumberOfUsersForDistributors,
  getUsersForDistributor,
} from '@/redux/distributors/distributors.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { deleteUser } from '@/redux/users/users.actions.ts';
import {
  CreateUserResponse,
  GetUsersResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';

const userTableHeaders: TableHeaderType[] = [
  {
    id: 'full_name',
    text: 'Korisnik',
    className: 'max-sm:hidden',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: 'max-sm:hidden',
  },
  {
    id: 'country',
    text: 'Drzava',
    className: 'max-xl:hidden',
  },
  { id: 'email', text: 'Email', className: '' },
  {
    id: 'phoneNumber',
    text: 'Telefon',
    className: 'max-xl:hidden',
    isSortDisabled: true,
  },
  { id: 'parcelCount', text: 'Broj parcela', className: 'max-lg:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-xl:hidden' },
  { id: 'dateOfPayment', text: 'Datum plaćanja', className: 'max-2xl:hidden' },
  { id: 'paidSubscription', text: 'Platio', className: 'max-lg:hidden' },
  {
    id: 'actions',
    text: '',
    className: '',
    isSortDisabled: true,
  },
];

type FilterObject = {
  realPage: number;
  realRowsPerPage: number;
  realSortColumn: string;
  realSortAsc: boolean;
  realInputText: string;
};

const UsersForDistributorSection: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const users = useAppSelector(getUsersForDistributor);
  const totalNumberOfUsers = useAppSelector(
    getTotalNumberOfUsersForDistributors
  );
  const filteredNumberOfUsers = useAppSelector(
    getFilteredNumberOfUsersForDistributors
  );
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const createQueryParams = (filterObject: FilterObject) => {
    const {
      realPage,
      realRowsPerPage,
      realSortColumn,
      realSortAsc,
      realInputText,
    } = filterObject;
    return `?id=${params.id}&page=${realPage}&perPage=${realRowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchUsers = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredUsersForDistributor(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetUsersResponse | CreateUserResponse | UpdateUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: value,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const filterObject: FilterObject = {
        realPage: page,
        realRowsPerPage: rowsPerPage,
        realSortColumn: sortColumn,
        realSortAsc: !isSortAsc,
        realInputText: inputText,
      };
      const queryParams = createQueryParams(filterObject);
      await callFetchUsers(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: true,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const filterObject: FilterObject = {
      realPage: selectedPage,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    setPage(selectedPage);
    await callFetchUsers(queryParams);
  };

  const onRemoveUserClick = async (userId: string) => {
    // @ts-ignore
    const response = await dispatch(
      removeDistributorForUser({ userId })
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno uklonjen distributer za korisnika');
  };

  const onRowsPerPageChange = async (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: value,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const fetchUsers = async () => {
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    const response = await callFetchUsers(queryParams);
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  const onDeleteUserClick = async (userId: string) => {
    // @ts-ignore
    const response = await dispatch(deleteUser({ userId })).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    await fetchUsers();
    toast.success('Korisnik je uspešno izbrisan');
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNumberOfUsers]);

  return (
    <>
      <div className="pt-5 px-5 pb-2 flex flex-row items-center justify-end gap-5">
        <div className="relative ml-auto flex-1 md:grow-0">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="pretraga"
            placeholder="Pretraga..."
            className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="border-b border-gray-900/10 pt-5 md:px-5 text-sm">
        <div className="flex flex-col">
          <Table>
            <CustomTableHeader
              tableName="users"
              tableHeaders={userTableHeaders}
              onHeaderCellClick={onColumnClick}
            />
            <TableBody>
              {users.map((user, index) => (
                <UserTableRow
                  key={`user-table-distributor-data-user-${index + 1}`}
                  user={user}
                  onDeleteUser={onDeleteUserClick}
                  showRemoveFromDistributor
                  onRemoveUserClick={onRemoveUserClick}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PaginationSection
        page={page}
        rowsPerPage={rowsPerPage}
        filteredNumber={filteredNumberOfUsers}
        totalNumber={totalNumberOfUsers}
        onPageChangeClick={onPageChangeClick}
        entity="korisnika"
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default UsersForDistributorSection;
