import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MultiSelectItem } from '@/components/controls/CustomFormMultiSelect.tsx';
import FERTDeviceFactorForm from '@/components/forms/devices/FERTDeviceFactorForm.tsx';
import FERTDeviceInfoForm from '@/components/forms/devices/FERTDeviceInfoForm.tsx';
import FERTDeviceSensorsForm from '@/components/forms/devices/FERTDeviceSensorsForm.tsx';
import FERTDeviceSupplementForm from '@/components/forms/devices/FERTDeviceSupplementForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { updateFERTDevice } from '@/redux/device/devices.actions.ts';
import {
  getAvailableZones,
  getFERTDeviceForEdit,
} from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { formAvailableZonesContent } from '@/utils/device.util.ts';
import { editFERTDeviceSchema } from '@/zod/device.types.zod.ts';

export type EditFERTDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: string;
  pHFactorM: string;
  zonesForDevice: string[];
  supplementOne: string;
  supplementTwo: string;
  supplementThree: string;
  supplementFour: string;
  supplementFive: string;
  supplementMixer: string;
  ecSensor: string;
  ecFactorK: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  waterLevelSensor: string;
  pHWater: string;
};

type EditFERTDeviceFormProps = {
  transformedFERTDevice: EditFERTDeviceFormData;
};

const EditFERTDeviceForm: FC<EditFERTDeviceFormProps> = ({
  transformedFERTDevice,
}) => {
  const device = useAppSelector(getFERTDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editFERTDeviceSchema),
    defaultValues: {
      id: '',
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      communicationModule: 'LTE',
      pHFactorK: '1',
      pHFactorM: '0',
      zonesForDevice: [],
      supplementOne: 'true',
      supplementTwo: 'true',
      supplementThree: 'true',
      supplementFour: 'false',
      supplementFive: 'false',
      supplementMixer: 'false',
      ecSensor: 'true',
      ecFactorK: '1',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
      waterLevelSensor: 'false',
      pHWater: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const [multiSelectData, setMultiSelectData] = useState<MultiSelectItem[]>([]);
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditFERTDeviceFormData) => {
    const dataToSend = {
      deviceToEdit: data,
      device,
    };
    // @ts-ignore
    const response = await dispatch(updateFERTDevice(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    onGiveUpClick();
  };

  useEffect(() => {
    if (transformedFERTDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedFERTDevice.name,
        color: transformedFERTDevice.color,
        communicationModule: transformedFERTDevice.communicationModule,
        zonesForDevice: transformedFERTDevice.zonesForDevice,
        supplementMixer: transformedFERTDevice.supplementMixer,
        flowMeter: transformedFERTDevice.flowMeter,
        notificationForWrongFlowMeter:
          transformedFERTDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedFERTDevice.pressureSensor,
        pHFactorK: transformedFERTDevice.pHFactorK,
        pHFactorM: transformedFERTDevice.pHFactorM,
        supplementOne: transformedFERTDevice.supplementOne,
        supplementTwo: transformedFERTDevice.supplementTwo,
        supplementThree: transformedFERTDevice.supplementThree,
        supplementFour: transformedFERTDevice.supplementFour,
        supplementFive: transformedFERTDevice.supplementFive,
        ecSensor: transformedFERTDevice.ecSensor,
        ecFactorK: transformedFERTDevice.ecFactorK,
        waterLevelSensor: transformedFERTDevice.waterLevelSensor,
        pHWater: transformedFERTDevice.pHWater,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedFERTDevice]);

  useEffect(() => {
    setMultiSelectData(formAvailableZonesContent(device, availableZones));
  }, [device, availableZones]);

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Accordion
          defaultValue="infoForm"
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceInfoForm
                form={form}
                multiSelectData={multiSelectData}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="factorsForm">
            <AccordionTrigger>Podešavanja koeficijenata</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceFactorForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="supplementForm">
            <AccordionTrigger>Prihrana</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSupplementForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Izmeni uredjaj"
        />
      </form>
    </Form>
  );
};

export default EditFERTDeviceForm;
