import { MoreHorizontal } from 'lucide-react';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  deleteFERTDeviceById,
  deleteStartDeviceById,
  deleteSensDeviceById,
} from '@/redux/device/devices.actions.ts';
import { getDevicesForParcel } from '@/redux/device/devices.selectors.ts';
import {
  CreateDeviceResponse,
  FetchDevice,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';

type ExistingDeviceProps = {
  device: FetchDevice;
  index: number;
  onDeviceClick: (device: FetchDevice) => void;
};

const ExistingDevice: FC<ExistingDeviceProps> = ({
  device,
  index,
  onDeviceClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onClick = () => {
    onDeviceClick(device);
  };

  const onDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const deleteFERTDevice = async () => {
    // @ts-ignore
    const response = await dispatch(deleteFERTDeviceById(device.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uredjaj je uspešno izbrisan');
  };

  const deleteStartDevice = async () => {
    // @ts-ignore
    const response = await dispatch(deleteStartDeviceById(device.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uredjaj je uspešno izbrisan');
  };

  const deleteSensDevice = async () => {
    // @ts-ignore
    const response = await dispatch(deleteSensDeviceById(device.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uredjaj je uspešno izbrisan');
  };

  const functionToCall: Record<string, () => void> = {
    FERT: deleteFERTDevice,
    SENS: deleteSensDevice,
    START: deleteStartDevice,
  };

  const onDeleteDeviceClick = async () => {
    await functionToCall[device.type]();
  };

  return (
    <>
      <li
        className={`border-[1px] border-b-gray-200 px-5 py-5 flex flex-row justify-between max-sm:w-full max-w-[500px] items-center ${index !== 0 ? 'mt-2' : ''}`}
      >
        <p>{device.name}</p>
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={onClick}>
                Izmeni uredjaj
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:text-red-500 cursor-pointer"
                onClick={onDeleteClick}
              >
                Obriši uredjaj
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </li>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete uredjaj?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati uredjaj."
        onContinueClick={onDeleteDeviceClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

const DevicesContent: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const devices = useAppSelector(getDevicesForParcel);

  const onDeviceClick = (device: FetchDevice) => {
    router(`/devices/edit/${device.type}/${params.parcelId}/${device.id}`);
  };

  const onParcelClick = () => {
    router(`/parcels/edit/${params.parcelId}`);
  };

  const onBackClick = () => {
    router(-1);
  };

  if (!devices.length) {
    return (
      <Card x-chunk="dashboard-06-chunk-0">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle>Uredjaji</CardTitle>
          <Button
            type="button"
            className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
            onClick={onParcelClick}
          >
            Parcela
          </Button>
        </CardHeader>
        <CardContent className="text-xl sm:mt-5 max-sm:mt-2">
          Izbrana parcela nema kreirane uredjaje
        </CardContent>
        <CardFooter>
          <Button
            className="bg-gray-600 text-white py-3 px-2 "
            onClick={onBackClick}
          >
            Nazad
          </Button>
        </CardFooter>
      </Card>
    );
  }

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader className="flex flex-row justify-between items-center">
        <CardTitle>Uredjaji</CardTitle>
        <Button
          type="button"
          className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
          onClick={onParcelClick}
        >
          Parcela
        </Button>
      </CardHeader>
      <CardContent>
        {devices.map((device, index) => (
          <ExistingDevice
            device={device}
            index={index}
            onDeviceClick={onDeviceClick}
            key={`parcel-device-${device.name}-${index + 1}`}
          />
        ))}
      </CardContent>
      <CardFooter>
        <Button
          className="bg-gray-600 text-white py-3 px-2 "
          onClick={onBackClick}
        >
          Nazad
        </Button>
      </CardFooter>
    </Card>
  );
};

export default DevicesContent;
