import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getNumberOfSubscribedUsersAction } from '@/redux/statistics/statistics.actions.ts';
import {
  getNumberOfNotSubscribedUsers,
  getNumberOfSubscribedUsers,
} from '@/redux/statistics/statistics.selectors.ts';
import { SubscribedUsersResponse } from '@/redux/statistics/statistics.types.ts';

const numberOfSubscribedChartConfig = {
  value: {
    label: 'Broj korisnika',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const SubscribedUsersStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const numberOfSubscribed = useAppSelector(getNumberOfSubscribedUsers);
  const numberOfNotSubscribed = useAppSelector(getNumberOfNotSubscribedUsers);
  const chartData = [
    { name: 'Pretplaćeni korisnici', value: numberOfSubscribed },
    { name: 'Nepretplaćeni korisnici', value: numberOfNotSubscribed },
  ];

  const handleErrorResponse = (response: SubscribedUsersResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchSubscribedUsers = async () => {
      // @ts-ignore
      const response = await dispatch(
        getNumberOfSubscribedUsersAction()
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchSubscribedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Statistika pretplaćenih korisnika</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Broj pretplaćenih korisnika</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={numberOfSubscribedChartConfig}>
                  <BarChart accessibilityLayer data={chartData}>
                    <CartesianGrid vertical={false} />
                    <YAxis
                      type="number"
                      dataKey="value"
                      hide={false}
                      axisLine={false}
                    />
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Bar dataKey="value" fill="#2b90d9" radius={8} />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SubscribedUsersStatistic;
