import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type EditParcelSettingsFormProps = {
  form: UseFormReturn<any>;
};

const EditParcelSettingsForm: FC<EditParcelSettingsFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="maxBetweenTwoShifts"
        labelText="Max vreme izmedju dve smene bez navodnjavanja u sekundama"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="flowMeterCapacity"
        labelText="Kapacitet protokomera"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="flowMeterFactorK"
        labelText="Faktor K protokomera"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="flowMeterFactorM"
        labelText="Fakotr M protokomera"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="pHFactorK"
        labelText="pH faktor K"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="minHumidityForAutomaticIrrigation"
        labelText="Minimalna vlažnost za automatski režim navodnjavanja (%)"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="optimalHumidityForAutomaticIrrigation"
        labelText="Optimalna vlažnost za automatski režim navodnjavanja (%)"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="durationOfTheActiveValve"
        labelText="Trajanje uključenog ventila (ms)"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="timeBetweenValves"
        labelText="Vreme između ventila kod ciklusnog (s)"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="coordinates"
        labelText="Koordinate"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
    </>
  );
};

export default EditParcelSettingsForm;
