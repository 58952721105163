import { FC } from 'react';

import DeliveryFERTDeviceForm from '@/components/forms/delivery/DeliveryFERTDeviceForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import { DeviceRevision, FERTDevice } from '@/redux/device/devices.types.ts';

type FERTDevicesTableProps = {
  userFERTDevices: FERTDevice[];
  revisionsFERT: DeviceRevision[];
};

const FERTDevicesTable: FC<FERTDevicesTableProps> = ({
  userFERTDevices,
  revisionsFERT,
}) => {
  return (
    <Card x-chunk="user-fert-devices">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          FERT uređaji
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto w-full">
          <Table className="min-w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[150px]">Device name</TableHead>
                <TableHead className="min-w-[150px]">
                  Device Serial Number
                </TableHead>
                <TableHead className="min-w-[150px]">DID</TableHead>
                <TableHead className="min-w-[150px]">Revision</TableHead>
                <TableHead className="min-w-[130px]">Zone 24V</TableHead>
                <TableHead className="min-w-[130px]">Pump 24Vac</TableHead>
                <TableHead className="min-w-[130px]">Mixer 24Vac</TableHead>
                <TableHead className="min-w-[100px]">FERT 0</TableHead>
                <TableHead className="min-w-[100px]">FERT 2</TableHead>
                <TableHead className="min-w-[100px]">FERT 3</TableHead>
                <TableHead className="min-w-[100px]">FERT 4</TableHead>
                <TableHead className="min-w-[100px]">FERT 5</TableHead>
                <TableHead className="min-w-[150px]">
                  FERT PID pH [fertmod 0]
                </TableHead>
                <TableHead className="min-w-[150px]">
                  FERT PID EC [fertmod 0]
                </TableHead>
                <TableHead className="min-w-[160px]">
                  FERT Proportional [fertmod1]
                </TableHead>
                <TableHead className="min-w-[160px]">
                  FERT Power [0--100%] [fertmod2]
                </TableHead>
                <TableHead className="min-w-[130px]">pH prihrane</TableHead>
                <TableHead className="min-w-[130px]">EC prihrane</TableHead>
                <TableHead className="min-w-[200px]">FLOW</TableHead>
                <TableHead className="min-w-[130px]">
                  Irrigation by time [min]
                </TableHead>
                <TableHead className="min-w-[130px]">
                  Irrigation by volume [m3]
                </TableHead>
                <TableHead className="min-w-[130px]">Water level</TableHead>
                <TableHead className="min-w-[200px]">Communication</TableHead>
                <TableHead className="min-w-[300px]">Comment</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userFERTDevices?.map((device, index) => (
                <DeliveryFERTDeviceForm
                  device={device}
                  revisionsFERT={revisionsFERT}
                  key={`FERT-device-table-row-${index + 1}`}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default FERTDevicesTable;
